import React from "react";

import { Navigate } from "react-router-dom";

// Típus ellenőrzés
import PropTypes from 'prop-types';

// egy egyserű komponens ami egy protected route-t valósít meg
const ProtectedRoute = ({children}) => {

    // Check if user is logged in
    const isAuthenticated = localStorage.getItem("token");

    return isAuthenticated != null ? children : <Navigate to="/login" />;

}

export default ProtectedRoute;

// Típus ellenőrzés a props-okra kötelező mert sonarlint figylemeztetést ad olyan mint a php estében a típus ellenőrzés
ProtectedRoute.propTypes = {
    //
    children: PropTypes.element.isRequired, // React element típus
};

/*
propTypes kisbetűvel:

Ez a komponens egy statikus tulajdonsága (property), amelyet a React használ 
a propok típusának ellenőrzésére. A propTypes kisbetűs, mivel ez egy szokásos 
JavaScript tulajdonság neve, amelyet a React definíciójában használnak.
Amikor egy komponensben megadod a propTypes tulajdonságot, azzal beállítod, 
hogy a React milyen típusokat vár az adott komponens propjaihoz.

PropTypes nagybetűvel: Egy objektum, amelyet a prop-types csomagból importálsz, 
és amely tartalmazza a különböző típusokat, mint például PropTypes.string, PropTypes.number, stb.
*/

/*
A hibaüzenet ('element' is missing in props validations) arra utal, hogy 
a ProtectedRoute komponensben a propTypes nincs megfelelően beállítva, 
és emiatt a SonarLint figyelmeztetést ad. A propTypes használatával biztosíthatod, 
hogy a komponens megkapja a megfelelő típusú props-okat.

1. propTypes hozzáadása a ProtectedRoute komponenshez
Először is telepítened kell a prop-types csomagot, ha még nincs telepítve:

!  npm install prop-types
*/