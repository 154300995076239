
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';

// Csak a vendégek számára elérhető útvonalak logika
const GuestRoute = ({children }) => {

    // Check if user is logged in
    const isAuthenticated = localStorage.getItem("token");

    return isAuthenticated == null ? children : <Navigate to="/dashboard" />;

};

export default GuestRoute;

// props check 
GuestRoute.propTypes = {
    children: PropTypes.element.isRequired
};
