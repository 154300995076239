// Router-hez
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";

// Layouts
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";

// Components
import Login from "./components/auth/loginForm"; // Login form
import Register from "./components/auth/registerForm"; // Register form
import Navbar from "./components/navbars/navbar";
import NavbarAuth from "./components/navbars/navbarAuth";
//
import ProtectedRoute from "./routes/protectedRoute";
import GuestRoute from "./routes/guestRoute";
//
import Profile from "./pages/profile";
//
import { CartProvider } from './context/cartContext';


// App.js main component with routes

/*
Igen, ebben a felépítésben az App komponens szinte minden esetben lefut, 
amikor a felhasználó navigál a különböző útvonalak között. 
Ez azért van, mert az App komponens az alapja az egész alkalmazásnak, 
és minden route váltáskor újra renderelődik mountolódik.
*/

/*
Tehát a Usestate a renderelési folyamathoz tartozik a UseEffect pedig nem,
az UseEffect alapértelmezetten egyszer fut mountolódáskor, kivéve most amikor
használjuk benne a setAuthenticated függvényt, mert az újra renderelést okoz.
*/

export default function App() {

  // Állapot az autentikáció kezelésére
  // A komponens belső állapotának kezelése
  // Az állapot értékének megváltoztatásakor frissíti a komponenst
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Ellenőrizzük a localStorage token-t amikor a komponens betöltődik
  // Mellékhatásokat kezel a komponens életciklusában a komponens minden újrarenderelése után fut
  useEffect(() => {
    //
    const token = localStorage.getItem("token");
    //
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <div className="containter">

      {/* CartProvider kosárkezélés a teljes alkalmazásban elérhetővé tettük */}
      <CartProvider>

      <Router>

        {/* Navbar */}
        {isAuthenticated ? (
          // Komponensnek átadhatok setState függvényt is, hogy állítsa az állapotot ami itt utána megváltozik :: setIsAuthenticated->isAuthenticated
          <NavbarAuth setIsAuthenticated={setIsAuthenticated} />
        ) : (
          <Navbar />
        )}

        {/* Routes */}
        <Routes>
          {/* Guest route*/}

          <Route
            exact
            path="/"
            element={
              <GuestRoute>
                <Home />
              </GuestRoute>
            }
          />

          {/* Login form átadom az állapot beállító függvényt */}
          <Route
            path="/login"
            element={
              <GuestRoute>
                <Login setIsAuthenticated={setIsAuthenticated} />
              </GuestRoute>
            }
          />

          <Route
            path="/register"
            element={
              <GuestRoute>
                <Register setIsAuthenticated={setIsAuthenticated} />
              </GuestRoute>
            }
          />

          {/*Guest Route*/}

          {/* Védett erőforrás dashboard */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* Védett erőforrás profil */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          {/* Védett erőforrás */}

          {/* 404 */}
          <Route path="*" element={<h1>404 Not Found</h1>} />

        </Routes>
      </Router>

      </CartProvider>

    </div>
  );
}


/*
Különbségek a useState és useEffect között

A useState arra szolgál, hogy egy komponens belső állapotát kezelje.

A useEffect arra szolgál, hogy mellékhatásokat kezeljen a komponens életciklusában.

Mikor fut?:

A useState az állapot értékének megváltoztatásakor frissíti a komponenst.

A useEffect az alapértelmezett viselkedés szerint a komponens minden újrarenderelése után fut, 
de ez szabályozható a dependency array használatával.

Hogyan kapcsolódnak?:

Gyakran előfordul, hogy egy useEffect hatására frissíted az állapotot useState segítségével, 
ahogy a példádban is látjuk. A useEffect-ben történik 
a mellékhatás (pl. adatok lekérése), majd a useState-tel frissíted az állapotot az adatok alapján.

*useState

Renderelési folyamathoz tartozik: A useState közvetlenül kapcsolódik a 
komponens renderelési folyamatához. Amikor az állapot megváltozik (setState), a React újrarendereli a komponenst, 
hogy tükrözze az állapotváltozásokat. Ez azt jelenti, hogy minden alkalommal, amikor a useState-tel beállítasz egy 
új értéket, a komponens újra fog renderelni.

*useEffect

Nem tartozik közvetlenül a renderelési folyamathoz: 

A useEffect viszont a renderelés után fut le, 
és olyan műveleteket kezel, amelyek nem tartoznak közvetlenül a JSX rendereléséhez. 
Ezek a műveletek "mellékhatások", mint például API-hívások, előfizetések, vagy a DOM-manipuláció. 
A useEffect nem befolyásolja közvetlenül a renderelést, de használható olyan állapotváltozások kiváltására, 
amelyek új renderelést eredményezhetnek.

Példa a különbségre
Ha a useState-tel megváltoztatod az állapotot, akkor az állapotváltozás automatikusan új renderelést eredményez:


const [count, setCount] = useState(0);

/Ez megváltoztatja az állapotot, és új renderelést eredményez

setCount(count + 1);

Ezzel szemben a useEffect a komponens minden renderelése után fut, és nem okoz közvetlenül új 
renderelést, hacsak nem változtatod meg az állapotot a useEffect-ben:

useEffect(() => {
  console.log("This runs after every render!");
}, []);

Ha ebben a useEffect-ben változtatnád az állapotot (pl. setCount-tal), 
az új renderelést indítana el, de maga a useEffect nem része a renderelési folyamatnak, hanem utána fut le.

*/