// Egy egyszerű hook, amely lehetővé teszi, hogy bárhol az alkalmazásban könnyen elérd a kosár állapotát és a dispatch függvényt.

import { useContext } from "react";
import { CartContext } from "../context/cartContext";

// Hook a kosár állapotának eléréséhez
export const useCart = () => {
    return useContext(CartContext);
};

