import { useEffect, useState } from "react";

export default function Dashboard() {

  // Felhasználói adatok állapotának kezelése
  const [user, setUser] = useState(null);

  // Felhasználói adatok betöltése a localStorage-ból
  useEffect(() => {
    const user = localStorage.getItem("user");

    // check user
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);


  // Spinner
  if (!user) {
    return (
        // sonarlint miatt output-ba kell burkolni
      <output className="d-flex justify-content-center align-items-center vh-100">
        <output className="spinner-border text-primary" style={{ width: "5rem", height: "5rem" }}>
        </output>
      </output>
    );
  }


  return (
    <div>
      <h1>Welcome, {user.name}!</h1>

      <p>Email: {user.email}</p>
      {/* További felhasználói információk megjelenítése */}
    </div>
  );
}
