import axios from "axios";

// Lehet dedikált axios példányt készíteni a kérésekhez, amely tartalmazza az alapértelmezett beállításokat
// Amikor a tokent minden kéréshez csatolni kell, akkor érdemes egy authAxios példányt készíteni

//! ÖTLET : ERRE KI LEHET ALAKÍTANI EGY KOMPLEX HTTP AXIOS KLIENTET, AMI MINDEN SZÜKSÉGES FUNKCIÓT TARTALMAZ
/*
const HttpApiClient = axios.create({

    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
    timeout: 10000,
    
    transformRequest: [(data, headers) => {
        // Do whatever you want to transform the data
        return data;
    }],
    
    transformResponse: [(data) => {
      // Do whatever you want to transform the data
      return data;
    }],
    validateStatus: (status) => {
        return status >= 200 && status < 300;
    },
    maxRedirects: 5,
    maxContentLength: 2000,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    proxy: {
        host: ' ',
        port: 12345
    }
});

// Akár interceptort is bevetve egy komplex Http kliens létrehozására
*/

// React előre definiált saját környezeti változói :::
//! console.log(process.env)

// Létrehozható ezen felül pluszban saját .env is
// A változók neveinek mind REACT_APP_ előtaggal kell kezdődniük, 
// különben a React nem ismeri fel őket. Bővebb leírás a Laravel-React szerverkezelési útmutatóban.

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_API_URL_PRODUCTION;

// Dinamikus paraméterrel működő Axios példány
const createAuthAxios = (token) => {
    //
    return axios.create({
      //  
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      withCredentials: true, // Ha Sanctum-ot használsz, ez szükséges lehet
    });
  };
  
  export default createAuthAxios;


  /* axios.create() metódus után = axios példány :: authAxios

            Amikor létrehozol egy Axios példányt az axios.create() függvénnyel, a config 
            és a data kezdetben üres marad, kivéve azokat a beállításokat, amelyeket az axios.create() 
            hívás során megadtál. Az alapértelmezett beállításokat (például a baseURL, headers, withCredentials) 
            megadhatod, de a config és a data általában a konkrét kérés (pl. GET, POST, stb.) hívásakor kerül kitöltésre.

            pl :: const postData = {
            email: 'user@example.com',
            password: 'password123'
            };

            authAxios.post('/login', postData)
            .then(response => {
                console.log(response.data); // A data itt a `postData`
            })
            .catch(error => {
                console.error('Error during login', error);
            });

            const postData = {
            email: 'user@example.com',
            password: 'password123'
            };

            Post kérés egyedi konfigurációval a beállítotton velül

            authAxios.post('/login', postData, {
            timeout: 5000,  // A kérés timeout beállítása 5 másodpercre
            headers: {
                'X-Custom-Header': 'customValue'  // Egyedi fejléc hozzáadása
            }
            })
            .then(response => {
            console.log(response.data);
            })
            .catch(error => {
            console.error('Error during login', error);
            });


            Amikor egy Axios példányt létrehozol az axios.create() segítségével, az
            alapértelmezett beállításokat (config) megadhatod, mint például a baseURL, 
            headers, stb. Ezek a beállítások lesznek érvényben minden kérésre, amit 
            ezzel a példánnyal indítasz.

            Amikor egy konkrét kérést (például get, post, stb.) hajtasz végre, megadhatsz 
            további konfigurációkat, amelyek a kéréshez kapcsolódnak. Ezeket a beállításokat 
            hozzáadja a meglévő konfigurációhoz, és ha van átfedés (például a headers esetében), 
            akkor az újonnan megadott beállítások felülírják az eredetieket.

            

                    +-----------------------------------------+
                  |               Axios Példány             |
                  +-----------------------------------------+
                  |                                         |
                  |  baseURL: '/api'                        | <-- Alapértelmezett URL
                  |                                         |
                  |  headers: {                             | <-- Alapértelmezett fejlécek
                  |    Authorization: 'Bearer token'        | 
                  |    Content-Type: 'application/json'     | 
                  |  }                                      |
                  |                                         |
                  |  withCredentials: true                  | <-- Hiteladatok küldése
                  |                                         |
                  +-----------------------------------------+
                  |                                         |
                  |  Metódusok:                             |
                  |    - GET: axiosPeldany.get(url, config) | <-- GET kérés metódusa
                  |    - POST: axiosPeldany.post(url, data, config)| <-- POST kérés
                  |    - PUT: axiosPeldany.put(url, data, config)  | <-- PUT kérés
                  |    - DELETE: axiosPeldany.delete(url, config) | <-- DELETE kérés
                  |    ...                                  | <-- További HTTP metódusok
                  |                                         |
                  +-----------------------------------------+


  */
