
// Ez a fájl tartalmazza a kosár állapotkezelését és a reducer függvényt, amely kezeli a kosár műveleteit (pl. elem hozzáadása, eltávolítása).

import React, { createContext, useReducer } from "react";

import PropTypes from "prop-types";

// useReducer és a useContext együttes használata
// useReducer: a változások kezelésére
// createContext: a globális állapot tárolására más komponensek számára elérhetővé teszi

// Kezdeti állapot
const initialState = {
  items: [],
  totalAmount: 0,
  totalItem: 0,
};

// Reducer függvény a változások kezelésére
// action : ami érkezik a disptach-től
function cartReducer(state, action) {

  // állapot kezelés
  switch (action.type) {


    case "ADD_ITEM": {
      // új elem hozzáadása

      // Ellenőrizzük hogy az elem már benne van e a kosárban : megkeressük az elem indexét 0 1 2 3 ... az items tömbben. 
      const existingItemIndex = state.items.findIndex((item) => item.id === action.item.id);

      // ha benne van már a kosárban kirakjuk változóba
      const existingItem = state.items[existingItemIndex];

      // ha benne van a kosárban
      let updatedItems;

      if(existingItem)
      {
        // Ha az elem benne van a kosárban, akkor frissítjük a mennyiségét. 
        const updateItem = {

            // ...oda adjuk a létező elemet minden tulajdonságát
            ...existingItem,

            // a mennyiséget beállítjuk tulajdonságként és növeljük 1-el
            quantity : existingItem.quantity + 1,
        }

        //  A meglévő elemeket másoljuk 
        updatedItems = [...state.items];

        // Az elemet frissítjük
        updatedItems[existingItemIndex] = updateItem;

      } // Ha az elem nincs benne a kosárban hozzáadjuk
      else {
        updatedItems = [...state.items,{...action.item, quantity: 1}];
      }

      return {
        // ...state: a régi állapotot másoljuk->teljes initialState-t másoljuk
        ...state,
        // state-ből az items: a régi elemeket is hozzáadjuk
        items: updatedItems,
        totalAmount: state.totalAmount + action.item.price,
        totalItem: state.totalItem + 1,
      };

    }

    case "REMOVE_ITEM" : {

        // Megkeressük a törölni kívánt elemet 
        const itemRemove = state.items.find((item)=> item.id === action.id);

        // Ha nincs ilyen elem akkor visszatérünk a régi állapottal
        if(!itemRemove)
        {
            return state;
        }

        let updateCartItems;

        // Ha egy darab van belőle akkor töröljük az elemet
        if(itemRemove.quantity === 1)
        {
            updateCartItems = state.items.filter((item) => item.id !== action.id);

        } // Ha több mint 1 darab van belőle csökkentjük a mennyiségét 
        else {

            const updateItem = {
                // odaadjuk minden tulajdonsággal az elemet
                ...itemRemove,
                // a mennyiséget csökkentjük 1-el
                quantity : itemRemove.quantity - 1,
            }

            //  A meglévő elemeket másoljuk
            updateCartItems = [...state.items];

            // Az elemet frissítjük
            updateCartItems[state.items.findIndex((item) => item.id === action.id)] = updateItem;

        }    

      return {
        ...state, 
        items: updateCartItems,
        totalAmount: state.totalAmount - itemRemove.price,
        totalItem: state.totalItem - 1,
      };

    }

    default:
      return state;
  }
}


/*********************/

// Globálissá tétel a kosár kontextust useContext segítségével csak annyi hogy globálisan elérjü a kosár állapotát state és a dispatch függvényt

// Kosár kontextus létrehozása
export const CartContext = createContext();

// Kosár kontextus szolgáltatója
export const CartProvider = ({ children }) => {
    
  // useReducer használata a változások kezelésére
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Kosár kontextus szolgáltatója
  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// exportáljuk a CartProvider-t
export default CartContext;

// props validation
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/*********************/

