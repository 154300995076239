import React from 'react';
import { useCart } from '../../hooks/useCart';
import PropTypes from 'prop-types';

const Product = ({ product }) => {

  // Kosár dispatch elérése
  const { dispatch } = useCart();

  // A kosárba való hozzáadás 1 db termék esetén.
  const addToCart = () => {
    dispatch({ type: 'ADD_ITEM', item: product });
  };

  return (
    <div>
      <h2>{product.name}</h2>
      <p>{product.price} USD</p>
      <button className="btn btn-danger" onClick={addToCart}>Add to Cart</button>
    </div>
  );
};

// A propTypes helyes definiálása
Product.propTypes = {
  // product: egy objektum, amelynek van 'name' és 'price' tulajdonsága  
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,   // Kötelező 'name' string
    price: PropTypes.number.isRequired,  // Kötelező 'price' szám
  }).isRequired,
};

export default Product;

