import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
//
import createAuthAxios from "../../utils/auth/authAxios";


export default function NavbarAuth({ setIsAuthenticated }) {
  // Navigáció hook
  const navigate = useNavigate();

  // Kijelentkezés SZERVER + FRONTEND
  const handleLogout = async (e) => {
    //
    e.preventDefault();

    try {

      // Token kinyerése a localStorage-ból
      const token = localStorage.getItem("token");

      // Saját AuthAxios instance létrehozása a token használatával
      const authAxios = createAuthAxios(token);      


      // POST kérelem küldése az API-nak a felhasználó kijelentkezéséhez SZERVERRŐL IS.
      // axios.post(url,{data},{options})
      // axiot.post(url, {data}, {headers{}, withCredentials})
     
      const response = await authAxios.post("api/logout");

      if (response.status === 200) {
        
        // Token és felhasználói adatok eltávolítása a localStorage-ból
        localStorage.removeItem("token");
        localStorage.removeItem("user");

        // Állapot frissítése
        setIsAuthenticated(false);

        // Navigálás a kezdőlapra
        navigate("/");
        //
      } else {
        console.error("Failed to logout");
      }
    } catch (error) {
      console.error("Hiba a kijelentkezés során:", error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to={"/dashboard"}>
          Navbar
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to={"/dashboard"}>
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={"/profile"}>
                Profile
              </NavLink>
            </li>
          </ul>
        </div>

        <button onClick={handleLogout} className="btn btn-danger">
          Logout
        </button>
        
      </div>
    </nav>
  );
}

//szokásos props típus ellenőrzés
NavbarAuth.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired, // Helyes beállítás
};
