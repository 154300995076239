import React from "react";
import Product from "../components/products/product";
import Cart from "../components/cart/cart";

const Home = () => {
  const products = [
    { id: 1, name: "Apple", price: 1.99 },
    { id: 2, name: "Banana", price: 0.99 },
    { id: 3, name: "Orange", price: 1.49 },
  ];

  return (
    <div className="container p-3 mx-auto">
      <h1>Product List</h1>
      {products.map((product) => (
        <Product key={product.id} product={product} />
      ))}

      {/* Kosár tartlama */}
      <Cart />
      
    </div>
  );
};

export default Home;
