import axios from "axios";

// Lehet dedikált axios példányt készíteni a kérésekhez, amely tartalmazza az alapértelmezett beállításokat

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_API_URL_PRODUCTION;

// React előre definiált saját környezeti változói :::
//! console.log(process.env)

// Létrehozható ezen felül pluszban saját .env is
// A változók neveinek mind REACT_APP_ előtaggal kell kezdődniük, 
// különben a React nem ismeri fel őket. Bővebb leírás a Laravel-React szerverkezelési útmutatóban.

const publicAxios = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Ha Sanctum-ot használsz, ez szükséges lehet
});

export default publicAxios;