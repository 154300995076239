import React from 'react';
import { useCart } from '../../hooks/useCart';

const Cart = () => {

  const { state, dispatch } = useCart();  // A kosár állapotának elérése

  // Törlés 
  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_ITEM', id });
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Your Cart</h2>
      <div className="card">
        <div className="card-body">

          {state.items.length === 0 ? (

            // Ha a kosár üres
            <p className="text-center">Your cart is empty</p>
          ) : (

            // Ha a kosár nem üres
            <ul className="list-group">
              {state.items.map((item) => (
                <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <div>
                    <h5>{item.name}</h5>
                    <p>Price: {item.price} USD</p>
                    <p>Quantity: {item.quantity}</p>
                  </div>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
          
        </div>
        {state.items.length > 0 && (
          <div className="card-footer">
            <h5>Total Amount: {state.totalAmount.toFixed(2)} USD</h5>
            <h5>Total Items: {state.totalItem}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
