import React, { useState } from "react";
//
import { useNavigate } from "react-router-dom";

// Saját axios instance
import publicAxios from "../../utils/auth/publicAxios";

// props validation
import PropTypes from "prop-types";

// Auth Components
// setIsAuthenticated props-t fogadja az APP.JS-ből
export default function LoginForm({ setIsAuthenticated }) {

  // State-ek a form adatok tárolására és a hibaüzenetek megjelenítésére
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Navigáció hook
  const navigate = useNavigate();

  // Login függvény
  const handleLogin = async (e) => {

    // Oldal újratöltésének megakadályozása
    e.preventDefault();

    try 
    {
      //
      const response = await publicAxios.post("api/login", {
        email,
        password,
      });

      if (response.data.error) {
        setError(
          "Something went wrong, please try again or check your credentials!"
        );
        return;
      }

      // Token mentése a localStorage-be
      localStorage.setItem("token", response.data.token);

      // User mentése a localStorage-be
      localStorage.setItem("user", JSON.stringify(response.data.user));

      // Állapot beállítása App.js-ben-megy vissza, visszajelez az App.js-nek
      setIsAuthenticated(true);

      // Továbbítás a dashboard-ra vagy másik védett oldalra
      navigate("/dashboard");

      // Ha hiba van akkor
    } catch (error) {
      setError("Login failed, please check your credentials");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row w-100">
        <div className="col-md-4 offset-md-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="text-center mb-4">Login</h3>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                {/* Ha van error kirakjuk ha nincs nemrakjuk ki error && ha van ... */}
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// PropTypes beállítása mindig a komponens után kell, hogy legyen
LoginForm.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired, // Helyes beállítás
};
