import { useState } from "react";
import { useNavigate } from "react-router-dom";
import publicAxios from "../../utils/auth/publicAxios";
//
import PropTypes from "prop-types";

export default function Registerform({ setIsAuthenticated }) {
  
  // Felhasználói adatok állapotának kezelése
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  //
  const [error, setError] = useState("");

  // Navigáció hook
  const navigate = useNavigate();

  // Regisztráció függvény
  const handleRegister = async (e) => {

    // Oldal újratöltésének megakadályozása
    e.preventDefault();

    // Regisztráció a szerveren
    try {

      // Ha a jelszavak nem egyeznek
      if (password !== passwordConfirm) {
        setError("Passwords do not match");
        return;
      }

      // Ha a jelszó nem elég hosszú 8 karakternél
      if (password.length < 8) {
        setError("Password must be at least 8 characters long");
        return;
      }

      // POST kérelem küldése az API-nak a felhasználó regisztrációjához
      const response = await publicAxios.post("api/register", {
        name: userName,
        email: email,
        password: password,
      });

     // console.log(response.status);

      // Ha a regisztráció sikeres
      if (response.status === 201 || response.status === 200) {

        // Token mentése a localStorage-be
        localStorage.setItem("token", response.data.token);

        // User mentése a localStorage-be
        localStorage.setItem("user", JSON.stringify(response.data.user));

        // App.js-ben Állapot frissítése
        setIsAuthenticated(true);

        // Továbbítás a dashboard-ra vagy másik védett oldalra
        navigate("/dashboard");

      } else {
        console.error("Failed to register");
      }
    } catch (error) {

      // Ha a regisztráció sikertelen és az Api hibát küld vissza és egyezik a formátum vagy...
      setError(error.response?.data?.message || "An unknown error occurred");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row w-100">
        <div className="col-md-4 offset-md-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="text-center mb-4">Register</h3>

              <form onSubmit={handleRegister}>
                <div className="mb-3">
                  <label htmlFor="userName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    placeholder="Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Password Confirm
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Password Confirm"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    required
                  />
                </div>

                {/* Ha van error kirakjuk ha nincs nemrakjuk ki error -> && ha van ... */}
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// PropTypes beállítása mindig a komponens után kell, hogy legyen
Registerform.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired, // Helyes beállítás
};
